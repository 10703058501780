//import { getJWTFromCookie } from '../../authentication/authentication'
import { config } from '../../config'
import {clearEmptyValues} from '../../utility/utility'

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

interface ICookies {
  [key: string]: string
}

export const getToken = (tokenType: string) => {
  const freshCookies = document.cookie.split('; ').reduce((prev, curr) => {
    const [key, value] = curr.split('=');
    prev[key] = value;
    return prev;
  }, {} as ICookies);

  return freshCookies[tokenType];
};

export const getJWTFromCookie = () => getToken(config.jwtToken);

export const getJWTFromLocalStorage = () => localStorage.getItem(config.jwtToken);

export const DEFAULT_HEADERS: HeadersInit = {
  'Content-Type': 'application/json'
}

const IEHeader = {
  'X-Request-IE-Cache-Override': 'true'
}

export const noCacheHeaders = {
  'Cache-Control': 'no-cache', // dumb IE caching XHR
  Pragma: 'no-cache'
}

export const cacheHeader = {
  'Cache-Control': 'cash-money' // get results from server cache
}

const GET_HEADERS: HeadersInit =
{
  ...DEFAULT_HEADERS,
  ...cacheHeader
}

const PUT_HEADERS: HeadersInit = {
  ...DEFAULT_HEADERS
}

const POST_HEADERS: HeadersInit = {
  ...DEFAULT_HEADERS
}

const DELETE_HEADERS: HeadersInit = {
  ...DEFAULT_HEADERS
}

export const getConductivSessionToken = () => Office.context.roamingSettings.get('conductiv-session')

export const withConductivSessionHeader = () => ({
  'X-Conductiv-Session': getConductivSessionToken()
})

// remove header properties by setting 'clear' on any http header property
// { 'default-property1': 'clear', property2: 'keep me' } -> { property2: 'keep me' }
const clearHeaders = (headers?: HeadersInit) =>
  headers
    ? Object.entries(headers).reduce((acc, curr) => {
        const [k, v] = curr
        if (v !== 'clear') {
          return {
            ...acc,
            [k]: v
          }
        }

        return acc
      }, {} as HeadersInit)
    : {}

const withId = (url: string, id?: number) => (id ? `${url}/${id}` : url)

const withQuery = (url: string, query?: Record<string, string>) =>
  query ? `${url}?${new URLSearchParams(clearEmptyValues(query)).toString()}` : url

export const withJwtHeader = (token?: string) => ({
  Authorization: `bearer ${token ? token : isSafari ? getJWTFromLocalStorage() : getJWTFromCookie()}`
})

const DEFAULT_REQUEST: RequestInit = {
  credentials: 'include'
}

const fetcher = (url: string, request: RequestInit) =>
  fetch(url, {
    ...DEFAULT_REQUEST,
    ...request
  }).then(handleErrors)

const handleErrors = async (res: Response) => {
  if (!res.ok) {
    throw await res.json()
  }
  return res
}

const apiFactory = (url: string, includeJwtHeader = true) => {
  const get = <T>(query?: Record<string, string>, additionalHeaders: HeadersInit = {}, abortSignal?: any) =>
    fetcher(withQuery(url, query), {
      headers: clearHeaders({
        ...(includeJwtHeader ? withJwtHeader() : {}),
        ...GET_HEADERS,
        ...additionalHeaders
      }),
      method: 'GET',
      signal: abortSignal
    }).then((res) => res.json() as Promise<T>)

  const getById = <T>(id: number, query: Record<string, string> | undefined, additionalHeaders: HeadersInit = {}) =>
    fetcher(withQuery(withId(url, id), query), {
      headers: clearHeaders({
        ...(includeJwtHeader ? withJwtHeader() : {}),
        ...GET_HEADERS,
        ...additionalHeaders
      }),
      method: 'GET'
    }).then((res) => res.json() as Promise<T>)

  const put = <T>(
    id: number | undefined,
    query: Record<string, string> | undefined,
    body: T,
    additionalHeaders: HeadersInit = {}
  ) =>
    fetcher(withQuery(withId(url, id), query), {
      headers: clearHeaders({
        ...(includeJwtHeader ? withJwtHeader() : {}),
        ...PUT_HEADERS,
        ...additionalHeaders
      }),
      body: JSON.stringify(body),
      method: 'PUT'
    })

  const post = <T>(
    query: Record<string, string> | undefined,
    body: T,
    additionalHeaders: HeadersInit = {},
    abortSignal?: any
  ) =>
    fetcher(withQuery(url, query), {
      headers: clearHeaders({
        ...(includeJwtHeader ? withJwtHeader() : {}),
        ...POST_HEADERS,
        ...additionalHeaders
      }),
      signal: abortSignal,
      body: JSON.stringify(body),
      method: 'POST',
      credentials: 'same-origin'
    })

  const deleteM = (
    id: number | undefined,
    query: Record<string, string> | undefined,
    additionalHeaders: HeadersInit = {}
  ) =>
    fetcher(withQuery(withId(url, id), query), {
      headers: clearHeaders({
        ...({} /* includeJwtHeader ? withJwtHeader() : {} */),
        ...DELETE_HEADERS,
        ...additionalHeaders
      }),
      method: 'DELETE'
    })

  const deleteBody = <T>(query: Record<string, string> | undefined, body: T, additionalHeaders: HeadersInit = {}) =>
    fetcher(withQuery(url, query), {
      headers: clearHeaders({
        ...({} /* includeJwtHeader ? withJwtHeader() : {} */),
        ...DELETE_HEADERS,
        ...additionalHeaders
      }),
      body: JSON.stringify(body),
      method: 'DELETE'
    })

  const getString = (query?: Record<string, string>, additionalHeaders: HeadersInit = {}) =>
    fetcher(withQuery(url, query), {
      headers: clearHeaders({
        ...({} /* includeJwtHeader ? withJwtHeader() : {} */),
        ...GET_HEADERS,
        ...additionalHeaders
      }),
      method: 'GET'
    }).then((res) => res.text() as Promise<string>)

  return {
    get,
    getById,
    put,
    post,
    delete: deleteM,
    deleteBody,
    getString
  }
}

export {apiFactory, /*apiFactoryWithCache*/}
